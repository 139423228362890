








































import Vue, { PropType } from 'vue'
import UiRichText from '../../UiRichText/UiRichText.vue'
import { BannerData, Image, BannerBackground } from '../UiTextWithImages.types'

export default Vue.extend({
  name: 'UiBannerMobileApps',
  components: {
    UiRichText
  },
  inheritAttrs: false,
  props: {
    bannerData: {
      type: Object as PropType<BannerData>,
      required: true
    },
    bannerGallery: {
      type: Array as PropType<Image[]>,
      default: () => []
    },
    background: {
      type: Object as PropType<BannerBackground>,
      default: ({} as BannerBackground)
    }
  },
  computed: {
    backgroundImg (): string | false {
      return this.background?.publicUrl || false
    }
  }
})

