// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1f0f{background-size:cover;background-repeat:no-repeat;background-position:50%;overflow:hidden}._1f0f .N_qF{padding:3.2rem 0 0;display:flex;flex-wrap:wrap}@media (min-width:48em){._1f0f .N_qF{flex-wrap:nowrap}}@media (min-width:62em){._1f0f .N_qF{padding:0}}._1f0f .N_qF ._2mZ4{max-width:100%;padding:1.6rem 0}@media (min-width:62em){._1f0f .N_qF ._2mZ4{max-width:420px}}._1f0f .N_qF ._2tFc{color:#fff}._1f0f .N_qF ._2tFc p{margin:.8rem 0}._1f0f .N_qF ._2tFc a{margin:.8rem 0 0;border-bottom:none;display:inline-block}._1f0f .N_qF ._2tFc a:first-of-type{margin-left:-1.2rem;margin-right:1rem}@media (min-width:62em){._1f0f .N_qF ._2tFc a:first-of-type{margin-right:5.5rem}}._1f0f .N_qF ._2tFc a:hover{border-bottom:none}._1f0f .N_qF .LlNv{display:flex;align-items:center}@media (min-width:48em){._1f0f .N_qF ._1MJB{min-width:420px}}._1f0f .N_qF .oJW1{width:100%;align-self:flex-end}html[dir=rtl] ._1f0f .N_qF ._2tFc a:first-of-type{margin-right:-1.2rem;margin-left:1rem}@media (min-width:62em){html[dir=rtl] ._1f0f .N_qF ._2tFc a:first-of-type{margin-left:5.5rem}}", ""]);
// Exports
exports.locals = {
	"root": "_1f0f",
	"appBanner": "N_qF",
	"content": "_2mZ4",
	"text": "_2tFc",
	"column": "LlNv",
	"columnContent": "_1MJB",
	"imageWrapp": "oJW1"
};
module.exports = exports;
